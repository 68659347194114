export async function load(key, endpoint = key) {
  try {
    const { data } = await this.$axios.get(endpoint)
    this[key] = data
    return data
  } catch (error) {
    this.$alert.error()
    return error
  }
}

export async function initialLoadingForTotalCosts(startDate, endDate) {
  this.loading = true
  await Promise.all([
    this.load(
      'evaluationForTotalCost',
      `evaluations/evaluation-for-total-cost/${startDate}/${endDate}`,
    ),
  ])
  this.loading = false
}

export async function initialLoadingForInternalShifts(startDate, endDate) {
  this.loading = true
  await Promise.all([
    this.load(
      'employeesEvaluations',
      `evaluations/employees/for-date-span/${startDate}/${endDate}`,
    ),
  ])
  this.loading = false
}
